import React from 'react'

//In svg-icons we use className outline to refer to icon lines, and className innerBackground for 
//the icon background inside the circle for easier css styling

function ScrollDownSVG() {
	return (
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			width="100%" viewBox="0 0 128 128" enableBackground="new 0 0 128 128" space="preserve">
			<path fill="none" opacity="1.000000" stroke="none"
				d="
M1.000000,50.000000 
	C1.000000,33.333332 1.000000,17.166666 1.000000,1.000000 
	C43.666668,1.000000 86.333336,1.000000 129.000000,1.000000 
	C129.000000,43.666668 129.000000,86.333336 129.000000,129.000000 
	C86.333336,129.000000 43.666668,129.000000 1.000000,129.000000 
	C1.000000,102.833336 1.000000,76.666664 1.000000,50.000000 
M113.318214,101.803665 
	C121.080757,91.747002 125.368713,80.219452 125.665237,67.677361 
	C126.517494,31.628269 96.722527,2.437867 59.535488,5.658533 
	C24.412712,8.700418 -1.423185,43.421089 6.522112,77.890198 
	C12.051552,101.878593 26.872425,117.866829 50.723225,123.737770 
	C75.528885,129.843735 96.542007,122.188843 113.318214,101.803665 
z"/>
			<path fill="#FFFFFF" className="outline" opacity="1.000000" stroke="none"
				d="
M113.129189,102.106850 
	C96.542007,122.188843 75.528885,129.843735 50.723225,123.737770 
	C26.872425,117.866829 12.051552,101.878593 6.522112,77.890198 
	C-1.423185,43.421089 24.412712,8.700418 59.535488,5.658533 
	C96.722527,2.437867 126.517494,31.628269 125.665237,67.677361 
	C125.368713,80.219452 121.080757,91.747002 113.129189,102.106850 
M34.912010,21.316362 
	C19.766689,32.559814 11.722703,47.331741 11.832496,66.473801 
	C12.008176,97.103333 38.715786,121.474716 69.728409,119.040451 
	C100.421410,116.631271 123.325523,87.865616 118.533127,57.745804 
	C112.288551,18.499073 69.254921,-0.585848 34.912010,21.316362 
z"/>
			<path fill="none" opacity="1.000000" stroke="none"
				d="
M35.223633,21.126785 
	C69.254921,-0.585848 112.288551,18.499073 118.533127,57.745804 
	C123.325523,87.865616 100.421410,116.631271 69.728409,119.040451 
	C38.715786,121.474716 12.008176,97.103333 11.832496,66.473801 
	C11.722703,47.331741 19.766689,32.559814 35.223633,21.126785 
M75.560280,74.069862 
	C80.999626,68.026672 86.532890,62.064091 91.826569,55.895882 
	C93.640327,53.782478 97.956764,51.564453 94.184952,48.254902 
	C90.716293,45.211349 88.973907,49.537273 87.200500,51.442112 
	C79.882881,59.302055 72.798721,67.379349 65.415512,75.614616 
	C57.446178,66.790634 49.815250,58.290443 42.112339,49.855988 
	C40.599316,48.199268 38.955036,45.903149 36.345352,48.228561 
	C33.793270,50.502659 35.636253,52.403385 37.210667,54.153111 
	C43.429291,61.064175 49.675816,67.950203 55.928146,74.830841 
	C65.633102,85.511063 65.638695,85.505981 75.560280,74.069862 
z"/>
			<path fill="#FFFFFF" className="outline" opacity="1.000000" stroke="none"
				d="
M75.315887,74.326042 
	C65.638695,85.505981 65.633102,85.511063 55.928146,74.830841 
	C49.675816,67.950203 43.429291,61.064175 37.210667,54.153111 
	C35.636253,52.403385 33.793270,50.502659 36.345352,48.228561 
	C38.955036,45.903149 40.599316,48.199268 42.112339,49.855988 
	C49.815250,58.290443 57.446178,66.790634 65.415512,75.614616 
	C72.798721,67.379349 79.882881,59.302055 87.200500,51.442112 
	C88.973907,49.537273 90.716293,45.211349 94.184952,48.254902 
	C97.956764,51.564453 93.640327,53.782478 91.826569,55.895882 
	C86.532890,62.064091 80.999626,68.026672 75.315887,74.326042 
z"/>
		</svg>
	)
}

export default ScrollDownSVG