import React from 'react'

//In svg-icons we use className outline to refer to icon lines, and className innerBackground for 
//the icon background inside the circle for easier css styling

function github() {
	return (
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			width="100%" viewBox="0 0 190 190" enableBackground="new 0 0 193 193" space="preserve">
			<path fill="#7A7A7A" className="outline" opacity="1.000000" stroke="none"
				d="
M11.263853,135.623734 
	C5.385546,121.982422 2.334508,108.044029 3.111140,93.632217 
	C4.347367,70.691818 12.114279,50.362019 28.100084,33.240875 
	C42.924438,17.363672 60.895561,7.477434 82.085945,4.223710 
	C107.980568,0.247663 131.902374,6.012191 153.211884,21.687857 
	C173.341049,36.495247 185.125336,56.592381 189.741379,80.787567 
	C193.040298,98.079079 191.016373,115.243729 184.626648,131.646942 
	C175.860229,154.151382 160.310272,170.802673 138.782822,181.527023 
	C123.349289,189.215546 106.659790,192.403473 89.508171,190.863358 
	C68.986603,189.020615 50.700970,181.477829 34.987301,167.658035 
	C24.790792,158.690460 17.264086,148.035370 11.263853,135.623734 
M25.465775,146.935913 
	C30.259523,152.073242 34.697987,157.607010 39.909870,162.277771 
	C60.103474,180.374725 83.828430,186.959961 110.567787,183.023209 
	C133.815475,179.600494 152.499222,168.040680 166.527740,149.644730 
	C181.662933,129.797577 187.033554,107.030243 182.977005,82.266914 
	C179.997787,64.080101 171.556488,48.459332 158.754669,35.539188 
	C150.958557,27.671022 141.775528,21.339746 131.274124,16.949751 
	C113.196609,9.392646 94.776070,7.795988 75.904305,12.614732 
	C59.127892,16.898445 44.847416,25.520182 33.069954,38.367081 
	C19.640900,53.015541 11.839977,70.242592 10.133095,89.865410 
	C8.356290,110.292084 13.703084,129.188095 25.465775,146.935913 
z"/>
			<path fill="" className='innerBackground' opacity="1.000000" stroke="none"
				d="
M25.268913,146.625229 
	C13.703084,129.188095 8.356290,110.292084 10.133095,89.865410 
	C11.839977,70.242592 19.640900,53.015541 33.069954,38.367081 
	C44.847416,25.520182 59.127892,16.898445 75.904305,12.614732 
	C94.776070,7.795988 113.196609,9.392646 131.274124,16.949751 
	C141.775528,21.339746 150.958557,27.671022 158.754669,35.539188 
	C171.556488,48.459332 179.997787,64.080101 182.977005,82.266914 
	C187.033554,107.030243 181.662933,129.797577 166.527740,149.644730 
	C152.499222,168.040680 133.815475,179.600494 110.567787,183.023209 
	C83.828430,186.959961 60.103474,180.374725 39.909870,162.277771 
	C34.697987,157.607010 30.259523,152.073242 25.268913,146.625229 
M156.012512,120.347435 
	C159.076736,112.492859 158.990997,104.288551 159.070496,96.022308 
	C159.220978,80.376572 153.183334,67.009956 143.088959,55.853909 
	C126.950302,38.017883 106.301369,31.704046 82.709740,36.013615 
	C58.084682,40.511967 41.714169,55.282768 33.884045,78.714371 
	C27.532808,97.720390 29.816462,116.441948 40.377621,133.645050 
	C48.693977,147.191589 60.966492,155.978226 76.136787,160.535095 
	C81.800713,162.236435 87.996735,161.091644 87.033463,152.693222 
	C86.807205,150.720566 86.944916,148.698029 87.011024,146.701599 
	C87.158920,142.235428 85.327934,139.545334 80.652908,138.961105 
	C74.964630,138.250244 71.140671,135.039017 68.924614,129.773468 
	C68.269180,128.216110 67.319702,126.782494 66.926888,126.063950 
	C69.398064,124.778008 72.857712,124.058678 73.401505,122.389442 
	C73.940887,120.733711 71.546234,118.119949 70.428085,115.931610 
	C70.356262,115.791046 70.198265,115.693230 70.076508,115.579659 
	C63.737133,109.666550 61.711842,102.554779 63.872356,94.133942 
	C65.078308,89.433609 66.534081,84.692238 66.873924,79.898239 
	C67.454964,71.702171 69.733513,70.356987 77.024986,73.840012 
	C79.981461,75.252266 83.753860,75.203819 87.174080,75.339790 
	C92.289818,75.543137 97.486320,74.813431 102.527206,75.446350 
	C106.412979,75.934235 109.715996,76.202850 113.287727,74.031830 
	C120.013138,69.943916 122.766342,71.703537 123.031982,79.600288 
	C123.118858,82.182884 122.818985,85.195999 124.009872,87.250702 
	C128.822998,95.554993 127.737602,110.107803 119.846741,115.577370 
	C116.371056,117.986534 112.658638,120.124306 108.850349,121.964439 
	C104.767448,123.937263 103.128136,127.172256 103.029335,131.372040 
	C102.907944,136.532257 102.995338,141.697479 103.001045,146.860550 
	C103.011337,156.169479 111.225182,162.416306 119.573044,158.507156 
	C126.604385,155.214508 133.235367,150.740509 139.361725,145.922440 
	C147.442261,139.567505 151.608276,130.215790 156.012512,120.347435 
M86.529961,124.100235 
	C85.660812,123.736954 84.724815,122.966171 83.936493,123.095512 
	C82.313011,123.361870 80.012657,123.677567 79.388359,124.770943 
	C78.656799,126.052177 78.791626,128.697250 79.702507,129.794983 
	C80.559502,130.827835 83.831276,131.444351 84.426552,130.785568 
	C85.780212,129.287491 86.142998,126.894051 86.529961,124.100235 
z"/>
			<path fill="#7A7A7A" className="outline" opacity="1.000000" stroke="none"
				d="
M155.972107,120.745163 
	C151.608276,130.215790 147.442261,139.567505 139.361725,145.922440 
	C133.235367,150.740509 126.604385,155.214508 119.573044,158.507156 
	C111.225182,162.416306 103.011337,156.169479 103.001045,146.860550 
	C102.995338,141.697479 102.907944,136.532257 103.029335,131.372040 
	C103.128136,127.172256 104.767448,123.937263 108.850349,121.964439 
	C112.658638,120.124306 116.371056,117.986534 119.846741,115.577370 
	C127.737602,110.107803 128.822998,95.554993 124.009872,87.250702 
	C122.818985,85.195999 123.118858,82.182884 123.031982,79.600288 
	C122.766342,71.703537 120.013138,69.943916 113.287727,74.031830 
	C109.715996,76.202850 106.412979,75.934235 102.527206,75.446350 
	C97.486320,74.813431 92.289818,75.543137 87.174080,75.339790 
	C83.753860,75.203819 79.981461,75.252266 77.024986,73.840012 
	C69.733513,70.356987 67.454964,71.702171 66.873924,79.898239 
	C66.534081,84.692238 65.078308,89.433609 63.872356,94.133942 
	C61.711842,102.554779 63.737133,109.666550 70.076508,115.579659 
	C70.198265,115.693230 70.356262,115.791046 70.428085,115.931610 
	C71.546234,118.119949 73.940887,120.733711 73.401505,122.389442 
	C72.857712,124.058678 69.398064,124.778008 66.926888,126.063950 
	C67.319702,126.782494 68.269180,128.216110 68.924614,129.773468 
	C71.140671,135.039017 74.964630,138.250244 80.652908,138.961105 
	C85.327934,139.545334 87.158920,142.235428 87.011024,146.701599 
	C86.944916,148.698029 86.807205,150.720566 87.033463,152.693222 
	C87.996735,161.091644 81.800713,162.236435 76.136787,160.535095 
	C60.966492,155.978226 48.693977,147.191589 40.377621,133.645050 
	C29.816462,116.441948 27.532808,97.720390 33.884045,78.714371 
	C41.714169,55.282768 58.084682,40.511967 82.709740,36.013615 
	C106.301369,31.704046 126.950302,38.017883 143.088959,55.853909 
	C153.183334,67.009956 159.220978,80.376572 159.070496,96.022308 
	C158.990997,104.288551 159.076736,112.492859 155.972107,120.745163 
M80.772224,66.208801 
	C81.030296,66.409843 81.294762,66.794334 81.545517,66.785591 
	C93.539452,66.367409 105.634888,69.152908 117.218025,62.258999 
	C123.914375,58.273544 132.261948,60.573059 131.072052,70.867592 
	C130.674500,74.307060 129.786285,78.755577 131.433334,81.159492 
	C135.821030,87.563530 134.921310,94.388969 134.972031,101.224510 
	C135.068527,114.231201 128.363785,124.239899 116.061066,128.820053 
	C112.644081,130.092148 111.357574,132.260101 111.070602,135.296448 
	C110.774124,138.433243 110.833069,141.631577 111.045975,144.782074 
	C111.376740,149.676880 114.152084,151.555817 118.328072,149.059692 
	C124.197029,145.551636 130.433441,142.046204 135.039444,137.152908 
	C148.469589,122.885086 153.216263,105.630760 148.896774,86.441124 
	C143.024109,60.351524 118.260033,41.864346 91.543999,43.897968 
	C74.605087,45.187355 61.261890,52.464939 50.904388,65.630844 
	C41.140572,78.042091 37.889603,92.510902 40.254929,107.655205 
	C43.122078,126.012512 53.708660,139.614120 70.081200,148.505707 
	C72.370888,149.749191 75.001488,150.364960 77.474281,151.271286 
	C77.760094,150.708862 78.045906,150.146439 78.331718,149.584015 
	C77.094414,148.734573 75.872414,147.184174 74.617172,147.156769 
	C69.123611,147.036880 66.701355,143.281311 64.005547,139.398315 
	C61.791019,136.208572 61.344074,131.487915 56.308826,130.392746 
	C55.549351,130.227554 55.069637,126.888077 55.259071,125.101624 
	C55.344604,124.294983 57.315186,123.373108 58.564442,123.085785 
	C60.081261,122.736923 61.739498,123.002953 63.769634,123.002953 
	C63.259708,122.115250 63.115955,121.641876 62.802574,121.351692 
	C51.578899,110.959183 53.851284,97.854774 56.722534,85.395348 
	C57.702160,81.144394 58.942799,77.036148 58.997097,72.639427 
	C59.015610,71.140511 58.957150,69.639679 59.008595,68.142296 
	C59.174313,63.318577 61.228317,61.459354 65.911583,60.943546 
	C71.474129,60.330894 75.628075,63.421085 80.772224,66.208801 
z"/>
			<path fill="#7A7A7A" className="outline" opacity="1.000000" stroke="none"
				d="
M86.719238,124.482376 
	C86.142998,126.894051 85.780212,129.287491 84.426552,130.785568 
	C83.831276,131.444351 80.559502,130.827835 79.702507,129.794983 
	C78.791626,128.697250 78.656799,126.052177 79.388359,124.770943 
	C80.012657,123.677567 82.313011,123.361870 83.936493,123.095512 
	C84.724815,122.966171 85.660812,123.736954 86.719238,124.482376 
z"/>
			<path fill="" className='innerBackground' opacity="1.000000" stroke="none"
				d="
M80.454475,65.945992 
	C75.628075,63.421085 71.474129,60.330894 65.911583,60.943546 
	C61.228317,61.459354 59.174313,63.318577 59.008595,68.142296 
	C58.957150,69.639679 59.015610,71.140511 58.997097,72.639427 
	C58.942799,77.036148 57.702160,81.144394 56.722534,85.395348 
	C53.851284,97.854774 51.578899,110.959183 62.802574,121.351692 
	C63.115955,121.641876 63.259708,122.115250 63.769634,123.002953 
	C61.739498,123.002953 60.081261,122.736923 58.564442,123.085785 
	C57.315186,123.373108 55.344604,124.294983 55.259071,125.101624 
	C55.069637,126.888077 55.549351,130.227554 56.308826,130.392746 
	C61.344074,131.487915 61.791019,136.208572 64.005547,139.398315 
	C66.701355,143.281311 69.123611,147.036880 74.617172,147.156769 
	C75.872414,147.184174 77.094414,148.734573 78.331718,149.584015 
	C78.045906,150.146439 77.760094,150.708862 77.474281,151.271286 
	C75.001488,150.364960 72.370888,149.749191 70.081200,148.505707 
	C53.708660,139.614120 43.122078,126.012512 40.254929,107.655205 
	C37.889603,92.510902 41.140572,78.042091 50.904388,65.630844 
	C61.261890,52.464939 74.605087,45.187355 91.543999,43.897968 
	C118.260033,41.864346 143.024109,60.351524 148.896774,86.441124 
	C153.216263,105.630760 148.469589,122.885086 135.039444,137.152908 
	C130.433441,142.046204 124.197029,145.551636 118.328072,149.059692 
	C114.152084,151.555817 111.376740,149.676880 111.045975,144.782074 
	C110.833069,141.631577 110.774124,138.433243 111.070602,135.296448 
	C111.357574,132.260101 112.644081,130.092148 116.061066,128.820053 
	C128.363785,124.239899 135.068527,114.231201 134.972031,101.224510 
	C134.921310,94.388969 135.821030,87.563530 131.433334,81.159492 
	C129.786285,78.755577 130.674500,74.307060 131.072052,70.867592 
	C132.261948,60.573059 123.914375,58.273544 117.218025,62.258999 
	C105.634888,69.152908 93.539452,66.367409 81.545517,66.785591 
	C81.294762,66.794334 81.030296,66.409843 80.454475,65.945992 
z"/>
		</svg>
	)
}

export default github